#filter-form .filter ul {
    list-style-type: none;
    margin-bottom: 1 em;
    margin-left: 0;
    [type="checkbox"] + label, [type="radio"] + label {
        display: inline-block;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }


}