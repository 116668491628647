// flat colors



$primary: #446CB3;

$secondary: #ADB2BD;

$info: #89c4f4;

$alert: #F22613;

$success: #87D37C;

$warning: #F89406;



.alert-box {

  padding: 1.3rem;

  border-width: 0 0 0 0.35rem;

  font-family: 'Open Sans', sans-serif;

  font-size: 1rem;



  &.radius {

    border-radius: 0.6rem;

  }



  &.primary {

    border-left-color: $primary;

    background-color: scale-color($primary, $lightness: 40%);

    strong {

      color: $primary;

    }

  }

  &.secondary {

    border-left-color: $secondary;

    background-color: scale-color($secondary, $lightness: 40%);

    strong {

      color: $secondary;

    }

  }

  &.info {

    border-left-color: $info;

    background-color: scale-color($info, $lightness: 40%);

    strong {

      color: $info;

    }

  }

  &.alert {

    border-left-color: $alert;

    background-color: scale-color($alert, $lightness: 40%);

    strong {

      color: $alert;

    }

  }

  &.success {

    border-left-color: $success;

    background-color: scale-color($success, $lightness: 40%);

    strong {

      color: $success;

    }

  }

  &.warning {

    border-left-color: $warning;

    background-color: scale-color($warning, $lightness: 40%);

    strong {

      color: $warning;

    }

  }

}
