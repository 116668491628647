#customer-wrapper {
  .center.row {
    //height: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    max-width: 500px;
  }

  .signup-panel {
    padding: 15px;
    i {
      font-size: 30px;
      line-height: 50px;
      color: #999;
    }

    form {
      input, span {
        height: 50px;
      }
    }

    .welcome {
      font-size: 26px;
      text-align: center;
      margin-left: 0;
    }

    .button {
      //margin-left: 35%;
      //margin-right: 35%;
    }
  }
  section.active {
    padding-top: 75px !important;
  }
  p.title {
    border-bottom: 1px solid #cccccc !important;
  }

  .content {
    height: 450px;
  }
}
.row.full {
  max-width: 100%;
}

#my-space-wrapper{

  .box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 25px;
    display: block;
    margin-top: 60px;
  }

  .box-icon {
    background-color: #569;
    border-radius: 50%;
    display: table;
    height: 100px;
    margin: 0 auto;
    width: 100px;
    margin-top: -61px;
    font-size: 3rem;

    span {
      color: #fff;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }

  }

  .info {

    h4 {
      font-size: 16px;//26px;
      letter-spacing: 2px;
      text-transform: uppercase;
      word-break: break-all;
    }
    p {
      color: #717171;
      font-size: 16px;
      padding-top: 10px;

    }

    a {
      border-radius: 2px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      transition: all 0.5s ease 0s;
      &:hover {
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
        transition: all 0.5s ease 0s;
      }
    }
  }

  .sidebar {
    display: inline-block;
    background-color: #34495e;
    h1 {
      color: #fff;
      text-transform: uppercase;
      font-weight: 800;
    }

    h2 {
      font-weight: 300;
      color: #B0CADB;
    }

    menu li a {
      color: #fff/*333*/;
      &.active,
      &.active:focus {
        background: #333;
        color: #fff;
      }

    }

  }
  @media only screen and (min-width: 64.063em) {

    .sidebar {
      //position: fixed;
      background-color: #34495e;
      top: 0;
      bottom: 0;

      h1 {
        color: #fff;
        text-transform: uppercase;
        font-weight: 800;
      }

      h2 {
        font-weight: 300;
        color: #B0CADB;
      }
    }

    .middle-header {
      text-align: left;
    }
  }

}